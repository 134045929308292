export const text = {
  title: "How Would You Like To Build Your Plan?",

  disabled: {
    tooltip: "This option is only available to those that have graduated. If you have graduated, please go to the human capital section of your profile and update your graduation date.",
  },

  // Optimized
  standard: {
    heading: "Using FitBUX's Financial Plan Optimizer (Coming Soon)",
    /*
    body: "Our AI-powered plan optimizer will automatically recommend how to best allocate your income in order to reach your goals faster.",
    */
    body: "We’re in the process of upgrading our technology to take into account upcoming changes in the tax code for 2023. Go ahead and create your plan manually for now and you’ll be notified when the Optimizer is updated. ",
  },
  student: {
    heading: "FitBUX's Optimized Financial Plan Builder (Coming Soon)",
    /*
    body: "Our AI will tell you what percent of your paycheck should go where to optimize your money. From student loans to investments, and even home buying. We'll optimize it all!",
    */
    body: "We’re in the process of upgrading our technology to take into account upcoming changes in the tax code for 2023. Go ahead and create your plan manually for now and you’ll be notified when the Optimizer is updated. ",
  },
  // Manually
  manual: {
    heading: "Manually",
    body: "Our easy-to-use plan building technology allows you to set up and simulate your plan.",
  }
};